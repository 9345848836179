.serviceTextInput {
  height: 45px;
  width: 544px;
  padding-right: 20px;
  border: 1px solid #ebeaea;
  border-radius: 6px;
  color: #434343;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  font-weight: 400;
  font-style: normal;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
  /* margin-bottom: 10px; */
  padding-left: 15px;
}

.serviceImageContainer {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.imageRemoveIconContainer {
  position: absolute;
  top: -10px;
  margin-left: 90px;
}

.imageRemoveIconContainer:hover {
  cursor: pointer;
}

.imageRemoveIcon {
  width: 25px;
  height: 25px;
}

.serviceTextAreaInput {
  height: 111px;
  width: 544px;
  padding-right: 20px;
  border: 1px solid #ebeaea;
  border-radius: 6px;
  color: #434343;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  font-weight: 400;
  font-style: normal;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-top: 10px;
  outline: none;
}

/*.ant-picker {*/

/*  height: 45px !important;*/

/*  width: 544px !important;*/

/*  border: 1px solid #ebeaea !important;*/

/*  border-radius: 6px !important;*/

/*  background-color: #ffffff !important;*/

/*  box-shadow: 0px 0px 10px 5px #c4d3f020 !important;*/

/*  margin-bottom: 10px !important;*/

/*}*/

/*.ant-picker-input > input {*/

/*  color: #434343 !important;*/

/*  font-size: 14px !important;*/

/*  font-family: Montserrat-Medium !important;*/

/*}*/

.timePickerServiceStyle {
  height: 45px;
  width: 544px;
  border: 1px solid #ebeaea;
  border-radius: 6px !important;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
  margin-bottom: 10px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(32, 37, 58, 0.25) !important;
}

.addServiceViewContainer {
  width: 100%;
  height: calc(100vh - 71px);
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.16);
}

.addServiceViewWrapper {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
}

.serviceAddFormContainer {
  /*margin-top: 40px;*/
  /*padding-left: 50px;*/
}

.addServiceDetailsText {
  color: #434343;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
}

.addServiceInputContainer {
  padding-top: 20px;
  padding-left: 50px;
}

.addCategoryInputContainer {
  padding-top: 50px;
  padding-left: 50px;
}

.serviceTitleText {
  color: #434343;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
}

.addServiceFavoriteText {
  margintop: 5px;
  color: #434343;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
}

.addCategoryBtnContainer:hover {
  cursor: pointer;
}

.addCategoryBtnContainer {
  width: 150px;
  height: 40px;
  background-color: #49a4d4;
  border-radius: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addCategoryBtnText {
  font-family: Montserrat;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}

.serviceTitleCol {
  padding-right: 0 !important;
}

.serviceTitleColInput {
  padding-left: 0 !important;
}

.serviceTitleColInput .toggles {
  /* margin-top: 60px; */
}

.addServiceImageBox {
  width: 100px;
  height: 100px;
  background-color: #efefef;
  border-radius: 10px;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryPlaceholderText {
  color: rgba(32, 37, 58, 0.25);
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.createVehiclePlaceholderText {
  color: #8a9ca5;
  font-size: 14px;
  font-family: Montserrat;
}

.createVehiclePlaceholderText {
  color: #8a9ca5;
  font-size: 14px;
  font-family: Montserrat;
}

.css-2b097c-container {
  width: 100%;
}

.addCategoryMainContainer {
  background-color: #eff3f6;
  height: 100vh;
}

.serviceDeleteBtnContainer:hover {
  cursor: pointer;
}

.css-1q0x60z {
  margin: 0 !important;
}

.serviceDeleteBtnContainer {
  width: 140px;
  height: 40px;
  border-radius: 49px;
  border: 1px solid #ff5f5f;
  background-color: #ffd6d6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryDeleteBtnContainer {
  width: 140px;
  height: 40px;
  border-radius: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryDeleteBtnContainer:hover {
  cursor: pointer;
}

.serviceDeleteBtnText {
  font-size: 14px;
  color: #ff5f5f;
  font-family: Montserrat;
  font-weight: 500;
}

.categoryTableContainer {
  height: calc(100vh - 155px);
}

.tableHeaderContainer {
  width: 100%;
  height: 51px;
  background-color: #fafafa;
  padding-left: 10px;
}

.categoryTableHeaderTextContainer {
  display: flex;
  align-items: center;
  height: 51px;
}

.categoryEditIcon:hover {
  cursor: pointer;
}

.categoryTableHeaderText {
  font-size: 16px;
  color: #8a9ca5;
  font-family: Montserrat;
  font-weight: 500;
}

.categoryTableBodyContainer {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 216px);
}

.categoryTableBody {
  width: 100%;
  height: 51px;
  background-color: #ffffff;
  padding-left: 10px;
  border-bottom: 1px solid rgba(32, 37, 58, 0.06);
}

.categoryTableBodyText {
  font-family: Montserrat;
  color: #353c42;
  font-size: 16px;
}

.stockItemTextInput {
  height: 42px;
  width: 180px;
  padding-right: 20px;
  border: 1px solid #ebeaea;
  border-radius: 6px;
  color: #434343;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
  padding-left: 15px;
}

.stockItemCardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.stockItemCard {
  height: 45px;
  border: 1px solid #EBEAEA;
  border-radius: 6px;
  padding: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.stockItemName {
  margin-right: 10px;
  color: #434343;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
}
