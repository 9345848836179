.MuiLinearProgress-colorPrimary {
  height: 10px !important;
}

.please-wait-text-container {
  margin-top: 20px;
  color: #868686;
  font-size: 16px;
  font-family: Montserrat;
}

.please-wait-text {
  text-align: center;
  color: #868686;
  font-size: 16px;
  font-family: Montserrat;
  margin-top: 8px;
}

.onboarded-text {
  text-align: center;
  margin-top: 4px;
  color: #868686;
  font-size: 16px;
  font-family: Montserrat;
}

.loadingProgressHeader {
  text-align: center;
  /* background-color: aquamarine; */
  margin-top: 50%;
  margin-bottom: 50px;
}

.loading-please-wait-text {
  text-align: center;
  color: #868686;
  font-size: 16px;
  font-family: Montserrat;
  margin-top: 35px;
  height: 100%;
}

.welcomeBackground {
  background-color: #f8f8f8;
  width: 100vw;
  height: 100vh;
}

.welcomeTopBoxContainer {
  width: 100%;
  height: 61px;
  background-color: #353c42;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.welcomeToNetliseText {
  font-size: 0.875em;
  color: #ffffff;
  font-family: 'Montserrat';
  font-weight: 600;
}

.welcomeEmployeeCardContainer {
  /* overflow-x: scroll; */
  width: 100%;
  display: flex;
  height: calc(100vh - 105px);
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-left: 25px;
  padding-top: 25px;
}

.masterSignOut {
  margin-left: 1rem;

}
.masterSignOut:hover {
  cursor: pointer;
}

.welcomeEmployeeBox {
  margin-right: 30px;
  margin-bottom: 30px;
  width: 320px;
  height: 122px;
  background-color: #49a4d4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  flex-direction: row;
}

.welcomeEmployeeBox:hover {
  cursor: pointer;
}

.welcomeImageContainer {
  width: 90px;
  height: 90px;
  border-radius: 50px;
}

.welcomeEmployeeName {
  font-family: Montserrat;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.125em;
  text-transform: capitalize;
}

.welcomeEmployeeType {
  font-family: Montserrat;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  font-size: 0.875em;
}

.productLoading {
  position: absolute;
  top: 45%;
  left: 50%;
}

.employeeNoUsers {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: calc(100% - 24px);
  flex-direction: column;

  h1 > {
    font-size: 24px;
  }
}

.subscriptionsCheck {
  margin-top: 80px;
  font-size: 16px;
}

.subsSignOut {
  margin-top: 120px;
  text-align: center;
  font-size: 18px;
  text-decoration: underline;
}
.subsSignOut:hover {
  cursor: pointer;
}

