.invoiceWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.invoiceWrapperInfo {
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 3px;
}


.calendarHeaderDateRageWrapper {
  width: 300px;
  height: 34px;
  background-color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 0px 10px 5px #00000016;
}

.calendarHeaderDateText {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
}

.calendarHeaderAppointmentButtonWrapper {
  width: 183px;
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #49a4d4;
  padding-left: 20px;
  padding-right: 10px;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.calendarHeaderAppointmentButton {
  color: white;
}

.calendarHeaderWrapperInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}





