@import '~react-calendar/dist/Calendar.css';

.navBar {
  background-color: #353c42;
  width: 100%;
  height: 71px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
}

.navBarContent {
  padding: 1px 20px;
  margin: 0px 0;
  /* text-align: center; */
}

.navBarHeaderText {
  color: #ffffff;
  font-size: 22px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: normal;
  font-style: normal;
  margin-left: 20px;
  margin-top: 20px;
}

.sidebarContainer {
  height: 89vh;
  width: 100%;
  margin-left: 10px;
  background-color: #eaeaea;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.sidebarSelectedContainer {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.sidebarTitle {
  margin-left: 10%;
  font-family: Montserrat;
  font-weight: 500;
  color: #434343;
  font-size: 16px;
}

.selectedTitleBox {
  width: 100%;
  height: 60px;
  background-color: #49a4d4;
  display: flex;
  align-items: center;
}

.navBarContentLeftAlign {
  display: flex;
  flex-direction: row;
}

.dropdown {
  background-color: initial !important;
}

.icon {
  /* padding-top: 20px; */
}

.tableFiltersContainer {
  justify-content: flex-start;
}

.tableFilters {
  height: 34px;
  min-width: 165px;
  background-color: #ffffff;
  border-radius: 4px;
  border: none;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
  padding-left: 10px;
  padding-right: 10px;
}

.tableFiltersContainerText {
  font-family: Montserrat;
  font-weight: 500;
  color: #000000;
  font-size: 14px;
}

.customStyles {
  background-color: aquamarine;
  color: black;
}

.dropdown {
  background-color: aqua;
}

.tableRow {
  background-color: #717578;
  height: 51px;
  justify-content: center;
}

.tableTopHeader {
  height: 45px;
  display: grid;
  align-items: center;
  background-color: #f4f8fb;
  /*padding-left: 10px;*/
  /*padding-bottom: 5px;*/
  /*padding-top: 5px;*/
}

.tableTopHeaderText {
  font-family: Montserrat !important;
  font-weight: 500;
  color: #000000 !important;
  font-size: 14px !important;
}

.tableBodyText {
  font-family: Montserrat;
  font-weight: 500;
  color: #434343;
  font-size: 14px;
}

.orderQueueBottomLine {
  height: 1px;
  background-color: rgba(32, 37, 58, 0.25);
}

.tableBodyTextContainer {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.tableStylistImage {
  width: 34px;
  height: 34px;
  border-radius: 50px;
}

.tableRowSortIconContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.tableBodyRowContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding-top: 5px !important;
}

.tableStylistSubHeaderText {
  font-family: Montserrat;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.85);
}

.tableRowSortIcon {
  margin-left: 10px;
}

.tableBody {
  min-height: 60px;
  background-color: #ffffff;
  display: grid;
  align-items: center;
  /*padding-left: 10px;*/
  padding-top: 5px;
}

.tableBody:hover {
  background-color: #f8f9fb;
}

.tableHeaderText {
  color: #ffffff;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  font-weight: normal;
  font-style: normal;
  padding-top: 15px;
  padding-left: 15px;
}

.sideBarSectionHeaders {
  color: #434343;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  /*margin-top: 23px;*/
  margin-bottom: 40px;
  /* width: 120px; */
}

.sideBarSectionHeaders:hover {
  cursor: pointer;
}

.navBackGround {}

.hrLine {
  /* width: 690px; */
  border-radius: 5px;
  height: 1px;
  background-color: #f1f1f2;
  border-radius: 50px;
  margin-left: -10px;
}

.searchText {
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  width: 98%;
  height: 50px;
  color: #707070;
  border: none;
}

.searchText:focus {
  outline: none;
}

.button {
  background-color: #ffffff;
  /* Green */
  border: 0.1px solid #49a4d4;
  color: #49a4d4;
  padding: 16px 32px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /* margin: 4px 2px; */
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: normal;
  font-style: normal;
}

.button:focus {
  outline: none;
}

.button:hover {
  background-color: #49a4d4;
  color: white;
}

.proIcon {
  /* padding-top: 17px; */
  z-index: 10;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.proNameText {
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  color: #262626;
  font-size: 14px;
  text-align: center;
}

.itemText {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  color: #262626;
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
}

.timeCardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.timeTypeText {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Montserrat;
}

.useCurrentBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.backBtn:hover {
  cursor: pointer;
}

.useCurrentDateText {
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 600;
  color: #49a4d4;
  font-size: 16px;
}

.useCurrentBtn {
  width: 589px;
  height: 54px;
  border: 1px solid #49a4d4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.useCurrentBtnContainer:hover {
  cursor: pointer;
}

.dateAndTimeContainer {
  margin-top: 50px;
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #434343;
  font-family: Montserrat;
  font-weight: 500;
}

.calenderBottomLine {
  width: 100%;
  height: 1px;
  background-color: rgba(32, 37, 58, 0.25);
  margin-top: 40px;
}

.timeMainCardContainer {
  padding-left: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.selectedTimeContainer:hover {
  cursor: pointer;
}

.selectedTimeContainer {
  width: 74px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-top: 20px;
}

.selectedTime {
  font-family: Montserrat;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

/*AppointmentDropDownComponent=======>*/

.checkOutButton {
  background-color: #f5f5f5;
  border: none;
  /*width: 100%;*/
  height: 51px;
  text-align: left;
  text-decoration: none;
  padding-left: 14px;
  padding-right: 14px;
  justify-content: space-between;
  align-items: center;
  transition-duration: 0.4s;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.buttonGroup {
  display: flex;
}

.printCheckoutButton {
  margin-right: 1rem;
}

.printCheckoutButton:hover {
  cursor: pointer;
}

.checkoutText {
  color: #434343;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
}

.appointmentDetailsButton {
  background-color: #f5f5f5;
  border: none;
  color: #434343;
  width: 88%;
  height: 51px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  transition-duration: 0.4s;
  cursor: pointer;
  margin-top: 9px;
  display: flex;
  flex-direction: row;
}

.yourTotalText {
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: bold;
  font-style: normal;
  color: #262626;
  font-size: 18px;
  padding-left: 14px;
}

.orderDetailsText {
  font-family: 'Montserrat';
  font-weight: bold;
  font-style: normal;
  color: #262626;
  font-size: 18px;
}

.container {
  display: flex;
  /* or inline-flex */
  flex-direction: row;
  justify-content: space-between;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 10px;
}

.billTexts {
  font-family: 'Montserrat';
  font-weight: bold;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  font-size: 14px;
  text-align: center;
}

.billProductsTexts {
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  font-size: 14px;
  text-align: center;
}

.appointmentBillContainer {
  height: 85vh !important;
  /*padding-left: 0px !important;*/
  /*margin-left: 10px !important;*/
}

.productTexts {
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
  color: #262626;
  font-size: 14px;
}

.mobileNumberText {
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  color: #262626;
  font-size: 14px;
  margin-top: 5px;
}

.billTotalTexts {
  font-family: 'Montserrat';
  font-weight: bold;
  font-weight: bold;
  font-style: normal;
  color: #262626;
  font-size: 14px;
  text-align: center;
}

.addToOrderQueueButton {
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  color: #cccccc;
  padding: 16px 14px;
  width: 100%;
  height: 67px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /* margin: 4px 2px; */
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: normal;
  font-style: normal;
}

.addToOrderQueueButton:focus {
  outline: none;
}

.blueCheckOutButton:focus {
  outline: none;
}

.appointmentCheckoutBtn {
  width: 100%;
  /*position: absolute;*/
  bottom: 0;
  margin-top: 18px;
}

.blueCheckOutButton {
  background-color: #49a4d4;
  border: 1px solid #cccccc;
  color: #ffffff;
  padding: 16px 14px;
  width: 100%;
  height: 67px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /* margin: 4px 2px; */
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: normal;
  font-style: normal;
}

.mainFilterLetterWrapper {
  margin-bottom: 20px;
}

.mainFilterLetter {
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: normal;
  font-style: normal;
  color: #c3c5cc;
  font-size: 16px;
  text-align: left;
}

/*CustomerCard=========>*/
.customerNameContainer {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.customerNameContainer:hover {
  cursor: pointer;
}

.nameOfCustomer {
  font-family: Montserrat;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  text-align: left;
  margin-bottom: 4px;
}

.selectedNameOfCustomer {
  font-family: Montserrat;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  text-align: left;
  margin-bottom: 4px;
}

.filterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stylistsFilterTagsButton {
  background-color: #ffffff;
  border: 0.2px solid #dedede;
  border-radius: 10px;
  color: #262626;
  /* padding: 16px 14px; */
  width: 100%;
  height: 46px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  /* margin: 4px 2px; */
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  margin: 2px;
}

.stylistsFilterTagsButton:hover {
  background-color: #39b97d;
  color: white;
}

.productImageCard {
  width: 96px;
  height: 70px;
  object-fit: contain;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
}

.serviceCardWrapper:hover {
  cursor: pointer;
}

/*ServiceCard=========>*/

.serviceCardWrapper {
  position: relative;
  margin-left: 20px;
  width: 96px;
  /* height: 100%; */
  margin-top: 12px;
}

.serviceCardImageWrapper {
  width: 96px;
  height: 70px;
  display: flex;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
}

.serviceCardImageTitle {
  font-family: 'Montserrat';
  font-weight: bold;
  color: #1f1f1f;
  font-size: 40px;
  text-align: left;
}

.serviceCardSelectedItemWrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
  border-radius: 3px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(64, 143, 185, 0.44);
}

.stockRemainingCount {
  position: absolute;
  left: 10px;
  top: 5px;
  color: #353C42;
  font-weight: 600;
  font-size: 14px;
  font-family: Montserrat;
  background-color: #ffffff;
  padding: 0px 4px;
  border-radius: 4px;
}

.promoCodeTitle {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  color: #1f1f1f;
  font-size: 16px;
  text-align: left;
}

.promoCodeTitleDescription {
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  color: #474747;
  font-size: 14px;
  text-align: left;
}

.promoCode {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  color: #1f1f1f;
  font-size: 14px;
  text-align: left;
}

.promoCodeRow {
  display: flex;
  /* or inline-flex */
  flex-direction: row;
  justify-content: space-between;
  padding-left: 14px;
  padding-right: 14px;
}

.applybutton {
  background-color: #ffffff;
  border: 0.2px solid #8a9ca5;
  border-radius: 50px;
  color: #8a9ca5;
  /* padding: 16px 14px; */
  width: 99px;
  height: 36px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  /* margin: 4px 2px; */
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  margin: 2px;
}

.ServiceProNameText {
  /* width: 96px; */
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  color: #262626;
  font-size: 14px;
  text-align: center;
  position: relative;
}

.subHeaderContainer {
  background-color: #f1f1f1;
  height: 51px;
  justify-content: center;
}

.subHeaderText {
  color: #88938f;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  padding-top: 15px;
  padding-left: 15px;
}

/*UserInformation=========>*/

.userInfoTextTitle {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
}

.userInfoRow {
  /* display: flex;  */
  /* flex-direction: row; */
  justify-content: space-between;
}

.lastCol {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.userInfoTitle {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
}

.userInfoEmail {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
}

.infoValues {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
}

.infoEmailValues {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  margin-top: 26px;
}

.editText {
  color: #4460f1;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  text-decoration: underline;
}

.editText:hover {
  cursor: pointer;
}

.revealText {
  color: #4460f1;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  padding-left: 16px;
}

.changePasscodeFieldsTitles {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  /* padding-left: 16px; */
}

.passcodeInputFieldsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userInformationInputWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #ebeaea;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
}

.userInformationInput {
  height: 50px;
  width: 100%;
  padding-right: 20px;
  border: none;
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
}

.userInformationNameInput {
  height: 50px;
  width: 367px;
  padding-right: 20px;
  border: 1px solid #ebeaea;
  border-radius: 6px;
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
  margin-bottom: 10px;
  padding-left: 20px;
}

.inputStyles {
  height: 55px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #ebeaea;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
}

input:focus {
  outline: none;
}

.saveCancelButtonsRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 46px;
}

/*SaveButton=======>*/

.saveButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px;
  background-color: #49a4d4;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  margin-right: 16px;
  border-radius: 50px;
  padding-left: 19px;
  padding-right: 24px;
  width: 159px;
}

.saveButtonText {
  color: #ffffff;
  font-size: 16px;
  outline: none;
  text-align: center;
  font-family: 'Montserrat';
}

/*CancelButton======>*/

.cancelButtonContainer {
  height: 39px;
  background-color: #ffffff;
  color: white;
  border: 1px solid #8a9ca5;
  cursor: pointer;
  text-align: center;
  border-radius: 50px;
  padding-left: 19px;
  padding-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 122px;
}

.cancelButtonText {
  color: #8a9ca5;
  font-size: 16px;
  text-align: center;
  outline: none;
  font-family: 'Montserrat';
}

/*Wifi========>*/

.wifiWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 45px;
  padding-top: 37px;
}

.wifiText {
  color: #262626;
  font-size: 16px;
  font-family: 'Montserrat';
}

/*WifiZone==========>*/

.wifiZoneWrapper:hover {
  background-color: #49a4d4;
}

.wifiZoneWrapperInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 22px;
}

.wifiZoneWrapperInfo:hover {
  cursor: pointer;
}

.wifiZoneText {
  color: #262626;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 600;
}

.wifiZoneDisconnectText {
  color: #ff5f5f;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
  margin-right: 43px;
}

.wifiZoneJoinWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.wifiZoneLine {
  margin-top: 22px;
  border: 1px solid #20253a06;
}

/*PrinterSetup=======>*/

.printerSetupWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 22px;
}

.printerSetupText {
  color: #11221c;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  min-width: 180px;
}

.printerSetupDeviceSelect {
  width: 550px;
  height: 44px;
  background-color: #ebebeb;
  border: none;
  padding-left: 10px;
  border-radius: 4px;
}

.printerSetupDeviceInput {
  width: 550px;
  height: 44px;
  background-color: #ffffff;
  border: 0.2px solid #ebebeb;
  padding-left: 10px;
  border-radius: 4px;
}

.printerSetupButtonsWrapper {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.horizontalLine {
  /* width: 690px; */
  border: 0.2px solid #f7f6f6;
}

.deviceTypeDropDown {
  width: 550px;
  height: 44px;
  background-color: #ebebeb;
  border: none;
  padding-left: 10px;
  border-radius: 4px;
}

.dropDown {
  width: 550px;
  height: 44px;
  background-color: #ffffff;
  border: 0.2px solid #ebebeb;
  padding-left: 10px;
  border-radius: 4px;
}

/*CloseCashSummary=================>*/

.closeCashSummaryWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 17px 14px;
}

.closeCashDate {
  color: #11221c;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
}

.closeCashWorkingHoursTag {
  height: 34px;
  display: flex;
  margin-left: 25px;
  padding-left: 34px;
  padding-right: 34px;
  border-radius: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f4f8fa;
}

.closeCashWorkingHoursTagText {
  color: #3e7aa1;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
}

.closeCashWorkingHoursValueText {
  color: #3e7aa1;
  font-size: 14px;
  margin-left: 5px;
  font-family: Montserrat;
  font-weight: bold;
}

.generateButtonContainer {
  width: auto;
  height: 34px;
  background-color: #f5f5f5;
  color: white;
  padding: 14px 20px;
  margin: 1px 0;
  border: 1px solid #4a4a4a;
  cursor: pointer;
  outline: none;
  text-align: center;
  /* margin-left: 50px; */
  border-radius: 4px;
}

/*TransactionDetails========>*/

.transactionDetailsTodayWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 17px 16px;
}

.transactionDetailsTodayWrapper div {
  display: flex;
}

.transactionDetailsTodayBoldText {
  color: #11221c;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 600;
  margin-right: 6px;
}

.transactionDetailsTodayText {
  color: #11221c;
  font-size: 14px;
  font-family: 'Montserrat';
}

.transactionDetailsPriceWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.transactionDetailsPriceWrapperInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.transactionDetailsPriceValue {
  color: #11221c;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-left: 21px;
}

.transactionDetailsPriceText {
  color: #11221c;
  font-size: 14px;
  font-family: 'Montserrat';
}

.transactionDetailsNameWrapper {
  margin-left: 17px;
  margin-top: 15px;
}

.transactionDetailsName {
  color: #11221c;
  font-size: 14px;
  margin-bottom: 5px;
  font-family: 'Montserrat';
  font-weight: 600;
}

.transactionDetailsMobile {
  color: #11221c;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 500;
}


.transactionDetailsProductWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 15px 18px 15px 17px; */
}

.transactionDetailsProductItemWrapper {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  justify-content: flex-start;
}

.transactionDetailsServiceWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 15px 18px 15px 17px;
}

.transactionDetailsImageWrapper {
  width: 53px;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
}

.transactionDetailsImageText {
  font-family: 'Montserrat';
  font-weight: bold;
  color: #1f1f1f;
  font-size: 25px;
  text-align: left;
}

.transactionDetailsServiceImage {
  width: 53px;
  height: 53px;
}

.transactionDetailsServiceWrapperInfo {
  margin-left: 16px;
}

.transactionDetailsProductHeaderText {
  color: #434343;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: 'Montserrat';
}

.transactionDetailsServiceText {
  color: #434343;
  font-size: 16px;
  font-weight: 500;
  margin-right: 6px;
  font-family: 'Montserrat';
}

.transactionDetailsServiceOptionText {
  color: #8A9CA5;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Montserrat';
}

.transactionDetailsBillWrapper {
  padding: 15px 17px 29px 17px;
}

.transactionDetailsBillHeaderWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* margin-bottom: 15px; */
}

.transactionDetailsBillHeaderText {
  color: #434343;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
}

.transactionDetailsBillWrapperInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 15px;
}

.transactionProductItemWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.transactionDetailsPriceCountWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.transactionDetailsBillServiceText {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
}

.transactionDetailsBillServiceOptionText {
  font-size: 12px;
  margin-left: 12px;
  color: #8A9CA5;
  font-family: 'Montserrat';
}

.transactionDetailsBillRefundText {
  color: #ff5f5f;
  font-size: 14px;
  font-family: 'Montserrat';
}

.transactionDetailsBillServiceTotalText {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
}

/*StylistSpecializationComponent=========>*/

.stylistSpecializationWrapper {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: scroll;
  max-height: 100px;
  align-items: flex-start;
}

.stylistSpecializationWrapperInfo {
  min-width: 106px;
  max-width: 140px;
  height: 36px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 14px;
  padding-right: 8px;
  padding-left: 8px;
}

.stylistSpecializationText {
  display: flex;
  flex-wrap: wrap;
  /*width: 70px;*/
  align-items: flex-start;
  font-size: 12px;
  font-family: Montserrat;
}

.categoryContainer {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: scroll;
  max-height: 100px;
  align-items: flex-start;
}

/*StylistComponent=========>*/

.stylistComponentWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow: scroll;
  height: calc(100vh - 240px);
  margin-top: 20px;
}

.stylistComponentImageWrapper {
  position: relative;
  margin-left: 10px;
  width: 114px;
  height: 150px;
  margin-bottom: 40px;
}

.stylistComponentImageWrapper:hover {
  cursor: pointer;
}

.stylistComponentImage {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 114px;
  height: 114px;
}

.stylistComponentNameText {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #262626;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.stylistComponentSelectedItem {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(64, 143, 185, 0.44);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 114px;
}

/*Services===============>*/

.serviceMainContainer {
  margin-top: 20px;
}

.serviceBtnMainContainer {
  margin-top: 18px;
  margin-left: 10px;
}

.customerLoading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.categoryContainer:hover {
  cursor: pointer;
}

.categoryBoxContainer {
  min-width: 106px;
  max-width: 120px;
  height: 36px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 14px;
  padding-right: 8px;
  padding-left: 8px;
}

.categoryTextContainer {
  display: flex;
  flex-wrap: wrap;
  /*width: 70px;*/
  align-items: flex-start;
  font-size: 12px;
  font-family: Montserrat;
}

.productLoading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.itemContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow: scroll;
  height: 460px;
  margin-top: 20px;
}

.serviceMainViewContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow: scroll;
  height: 60vh;
}

.Styles__serviceContainer__DmrbN {
  height: 53vh !important;
}

.itemBoxContainer {
  position: relative;
  margin-left: 30px;
  width: 114px;
  height: 150px;
  margin-bottom: 40px;
}

.itemBoxContainer:hover {
  cursor: pointer;
}

.itemImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedItemContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(64, 143, 185, 0.44);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 114px;
}

.keypadContainer {
  width: 97%;
  height: 54px;
  border: 1px solid #49a4d4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  position: absolute;
  top: 77vh;
  /*margin-bottom: 100px;*/
}

.keypadContainer:hover {
  cursor: pointer;
}

.appointmentDateCardContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

.appointmentDateText {
  color: rgba(0, 0, 0, 0.85);
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
}

/*SelectedCustomerCard======>*/

.selectedCustomerCardBottomWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

/*AppointmentDetailsComponent===========>*/

.detailsComponentContainer {
  width: 100%;
  height: 60vh;
  display: flex;
  overflow: scroll;
  padding: 17px 14px;
  flex-direction: column;
}

.keypadText {
  color: #49a4d4;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat';
  font-weight: 600;
}

.react-calendar {
  width: 100% !important;
  border: none !important;
}

.serviceContainer {
  height: 100% !important;
  padding-left: 0 !important;
}

/*CheckoutDetailsComponent======>*/

.checkoutDetailsWrapper {
  /*width: 100%;*/
  height: 100%;
  max-height: calc(100vh - 370px);
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 17px 14px;
  flex-direction: column;
}

.checkoutDetailsTotalText {
  color: #262626;
  font-size: 18px;
  margin-top: 7px;
  font-weight: bold;
  font-style: normal;
  font-family: 'Montserrat';
  font-weight: 600;
}

.checkoutTotalPrice {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  margin-top: 38px;
}

.checkoutTotalPriceText {
  color: #262626;
  font-size: 40px;
  font-family: 'Montserrat';
  font-weight: bold;
}

.checkoutCurrencyText {
  color: #262626;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 14px;
  margin-right: 2px;
}

.checkoutPerchesListWrapper {
  margin-top: 50px;
}

.checkoutPerchesListWrapperInfo {
  width: 100%;
  display: flex;
  padding-top: 10px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
}

.checkoutPerchesListServiceWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.checkoutPerchesListServiceText {
  color: #262626;
  font-size: 14px;
  text-align: start;
  font-family: Montserrat;
  /* padding-bottom: 8px; */
}


.orderDetailsPurchaseItemWrapper {
  width: 100%;
  display: flex;
  /* margin-bottom: 16px; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.orderDetailsPurchaseItemText {
  color: #262626;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  font-family: Montserrat;
}

.orderDetailsPurchaseItemPriceWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.orderDetailsPurchaseItemPriceText {
  color: #262626;
  font-size: 14px;
  text-align: start;
  font-family: Montserrat;
}

.orderDetailsPurchaseItemPriceOptionText {
  color: #8A9CA5;
  font-size: 12px;
  text-align: start;
  font-family: Montserrat;
}

.orderDetailsPurchaseItemModifierText {
  color: #49a4d4;
  font-size: 12px;
  text-align: start;
  font-family: Montserrat;
}

.checkoutPerchesListServiceText {
  color: #262626;
  font-size: 14px;
  text-align: center;
  font-family: Montserrat;
  /* padding-bottom: 8px; */
}


.checkoutPerchesListQuantityText {
  width: 26px;
  font-size: 14px;
  color: #262626;
  margin-right: 6px;
  text-align: center;
  font-family: Montserrat;
}

.checkoutPerchesListTaxWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.checkoutPerchesListTotalText {
  font-family: 'Montserrat';
  font-weight: bold;
  color: #262626;
  font-size: 14px;
  text-align: center;
}

.checkoutPerchesListDeleteWrapper {
  width: 110px;
  height: 37px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  margin-top: 4px;
}

.checkoutPerchesListDeleteWrapperInfo {
  width: 90px;
  height: 100%;
  background-color: #ff9696;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-family: 'Montserrat';
  color: #ffffff;
  font-size: 13px;
  text-align: center;
}

.checkoutPerchesListDeleteText {
  font-family: 'Montserrat';
  color: #ffffff;
  font-size: 13px;
  text-align: center;
  margin-left: 9px;
}

.checkoutPerchesListAddWrapper {
  width: 80px;
  height: 37px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/*StylistComponent===========>*/

/*InvoiceTable==========>*/

.invoiceTableWrapper {
  width: 100%;
  /*height: 100%;*/
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}

.invoiceTableRow {
  height: 51px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: #717578;
  justify-content: center;
}

.invoiceTableHeaderText {
  color: #ffffff;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  font-weight: normal;
  font-style: normal;
  padding-top: 15px;
  padding-left: 15px;
}

.invoiceTableTopHeader {
  height: 45px;
  background-color: #f4f8fb;
  display: grid;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.invoiceTableRowSortIconContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.invoiceTableTopHeaderText {
  font-family: Montserrat !important;
  font-weight: 500 !important;
  color: #000000 !important;
  font-size: 14px !important;
}

.invoiceTableRowSortIcon {
  margin-left: 10px;
}

.invoiceTableBody {
  width: 100%;
  height: 65px;
  background-color: #ffffff;
  /*display: grid;*/
  align-items: center;
  padding-top: 5px;
  cursor: pointer;
}

.invoiceTableBody:hover {
  background-color: #f8f9fb;
  /*background-color: red;*/
}

.invoiceTableBodyRowContainer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding-top: 5px !important;
}

.invoiceTableBodyRowContainerInfo {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: center !important;
}

.invoiceTableStylistImage {
  width: 34px;
  height: 34px;
  border-radius: 18px;
}

.invoiceTableBodyText {
  font-family: Montserrat;
  font-weight: 500;
  color: #434343;
  font-size: 14px;
}

.invoiceTableStylistSubHeaderText {
  font-family: Montserrat;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.85);
}

.invoiceTableBottomLine {
  height: 1px;
  border: none;
  margin-top: 20px;
  background-color: rgba(32, 37, 58, 0.25);
}

/*ReceiptCard==========>*/

.receiptCardWrapper {
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
}

.receiptCardHeaderWrapper {
  height: 51px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: #f5f5f5;
  justify-content: center;
}

.receiptCardHeaderText {
  color: #434343;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: normal;
  font-style: normal;
  padding-top: 15px;
  padding-left: 15px;
}

.receiptCardWrapperInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.receiptCardDetailsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 33px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.receiptCardDetailsSalonText {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
}

.receiptCardDetailsAddressText {
  color: #000000;
  font-size: 14px;
  font-family: Montserrat;
  margin-top: 9px;
}

.receiptCardDetailsBottomLine {
  height: 1px;
  width: 100%;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  text-decoration: black;
}

.receiptCardTimesWrapper {
  padding-left: 14px;
  padding-right: 14px;
}

.receiptCardTimesWrapperInfo {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.receiptCardDetailsTimeText {
  color: #000000;
  font-size: 12px;
  font-family: Montserrat;
}

.receiptCardServiceWrapper {
  margin-top: 90px;
}

.receiptCardDetailsServiceText {
  color: #000000;
  font-size: 14px;
  font-family: Montserrat;
  margin-top: 12px;
}

.receiptCardDetailsTotalText {
  color: #000000;
  font-size: 14px;
  margin-top: 12px;
  font-family: Montserrat;
  font-weight: bold;
  font-weight: bold;
}

.receiptCardDeleteWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 67px;
  background-color: #ffd6d6;
  border-width: 10px;
  border-color: #ff5f5f;
  margin-top: 63px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
}

.receiptCardDeleteText {
  color: #ff5f5f;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: bold;
  font-style: normal;
}

.receiptCardReOpenWrapper {
  height: 67px;
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #49a4d4;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
}

.receiptCardReOpenText {
  color: #ffffff;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: bold;
  font-style: normal;
}

/*CloseCashSummaryValues============>*/

.closeCashSummaryValuesWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
}

.closeCashSalesValue {
  color: #11221c;
  font-size: 36px;
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: normal;
  margin: 0px;
}

.closeCashSalesText {
  color: #11221c;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
  width: 80px;
  margin: 0px;
}

/*GenerateReportButton============>*/

.generateButtonWrapper {
  width: 225px;
  height: 59px;
  background-color: #f5f5f5;
  color: white;
  border: 1px solid #4a4a4a;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.generateButtonText {
  color: #4a4a4a;
  text-align: center;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  font-weight: normal;
  font-style: normal;
}

/*ServiceSummary==========>*/

.serviceSummaryRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 21px;
  padding-right: 32px;
}

.serviceSummaryRowEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 21px;
  padding-right: 32px;
}

.cashInRowEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 21px;
  /*padding-right: 32px;*/
}


.serviceSummaryText {
  color: #000000;
  font-size: 14px;
  font-family: Montserrat;
}

.serviceSummaryLine {
  height: 1px;
  background-color: #f1f1f2;
  margin-top: 21px;
  padding-right: 32px;
}

.serviceSummaryCashButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 26px;
  padding-right: 32px;
}

.serviceSummaryCashInWrapper {
  width: 91px;
  height: 34px;
  display: flex;
  border-radius: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #37b5d625;
  margin-right: 10px;
}

.serviceSummaryCashInText {
  color: #37b5d6;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.serviceSummaryCashOutWrapper {
  width: 91px;
  height: 34px;
  display: flex;
  border-radius: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ff5f5f25;
}

.serviceSummaryCashOutText {
  color: #ff5f5f;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

/*SubHeader==============>*/

.subHeaderWrapper {
  height: 51px;
  background-color: #fafafa;
  align-items: center;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.subHeaderNewText {
  color: #88938f;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
}

/*TransactionsList=========>*/

.transactionsListInputWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  /*width: 100%;*/
  border-bottom: 1px solid #20253a25;
  padding-left: 20px;
  padding-right: 20px;
}

.transactionsListInput {
  height: 50px;
  width: 100%;
  padding-right: 20px;
  border: none;
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
}

/*TransactionTableHeader=========>*/

.transactionTableHeaderWrapper {
  /*width: 100%;*/
  height: 51px;
  background-color: #fafafa;
  align-items: center;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.transactionTableHeaderDate {
  color: #11221c;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
}

.transactionTableHeaderIconWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.transactionTableHeaderIcon:hover {
  cursor: pointer;
}

/*TransactionDataRows============>*/

.transactionDataRowWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 27px;
}

.transactionDataRowWrapper:hover {
  background-color: #f8f9fb;
}

.transactionDataPriceWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.transactionDataIcon {
  width: 46px;
}

.transactionDataPriceWrapperInfo {
  margin-left: 21px;
}

.transactionDataRowValueRefund {
  color: #ff5f5f;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: bold;
  font-weight: bold;
  font-style: normal;
  margin: 0px;
}

.transactionDataRowValue {
  color: #11221c;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: bold;
  font-weight: bold;
  font-style: normal;
  margin: 0px;
}

.transactionDataRow {
  gap: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.transactionDataRowName {
  color: #11221c;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
}

.transactionDataRowTime {
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  color: #707070;
  border: none;
  width: 150px;
  text-align: end;
}

.transactionDataRowLine {
  height: 1px;
  background-color: #f1f1f2;
  margin-left: 15px;
  margin-right: 20px;
}

/*GenerateReceipt========>*/

.generateReceiptButton {
  background-color: #ffffff;
  border: 1px solid #49a4d4;
  color: #49a4d4;
  width: 100%;
  height: 54px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: normal;
  font-style: normal;
}

.generateReceiptButton:focus {
  outline: none;
}

/*ServiceItem==========>*/

.serviceItemWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow: scroll;
  margin-top: 20px;
}

.serviceItemBoxWrapper {
  position: relative;
  width: 114px;
  margin-bottom: 10px;
}

.serviceItemBoxWrapper:hover {
  cursor: pointer;
}

.serviceItemImage {
  width: 114px;
  height: 114px;
}

.serviceItemText {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #434343;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.serviceItemTextLabel {
  font-family: 'Montserrat';
  font-weight: 500;
  color: #434343;
  font-size: 14px;
  text-align: center;
}

.serviceItemAddBoxWrapper {
  width: 114px;
  height: 114px;
  border-radius: 10px;
  background-color: #efefef;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  border: 2px solid #ffffff;
  margin-top: 20px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.serviceItemAddBoxWrapperInfo {
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #767676;
  border-radius: 25px;
}

/*SettingsSideNav==========>*/

.settingsSideNavHeader {
  color: #434343;
  font-size: 16px;
  line-height: 25px;
  font-family: 'Montserrat';
  font-weight: 500;
}

.currentPasscodeContainer {
  padding-top: 20px !important;
}

.currentPasscodeLabelContainer {
  display: flex;
  align-items: center;
}

.userInformationTop {
  padding-top: 22px;
}

.userInformationContainer {
  padding: 32px;
}

.settingsSideNavHeader:hover {
  font-weight: 500;
  cursor: pointer;
}

.MuiIconButton-label {
  width: 26px !important;
  margin-top: 3px !important;
}

ul {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f1f1f1;
  border-top-left-radius: 3px;
  /*box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);*/
}

li a {
  cursor: pointer;
  display: block;
  color: #000000;
  padding: 18px 10px;
  text-decoration: none;
}

li a:hover {
  color: #ffffff;
  background-color: #49a4d4;
  font-family: 'Montserrat';
  font-weight: 600;
}

/*AddAppointmentSideNav==========>*/

.orderSidenavItemWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.orderSidenavOfferCount {
  width: 26px;
  height: 26px;
  display: flex;
  font-size: 16px;
  color: #434343;
  font-weight: 500;
  line-height: 19px;
  align-items: center;
  border-radius: 13px;
  justify-content: center;
  font-family: 'Montserrat';
  border: 1px solid #8A9CA5;
}


.addAppointmentSideNav {
  color: #434343;
  font-size: 16px;
  line-height: 25px;
  font-family: 'Montserrat';
  font-weight: 500;
}

.addAppointmentSideNav:hover {
  cursor: pointer;
}

/*SearchComponent=====>*/

.searchInputWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  width: 100%;
  border-bottom: 1px solid #20253a25;
  padding-left: 20px;
  padding-right: 20px;
}

.searchInput {
  height: 50px;
  width: 100%;
  padding-right: 20px;
  border: none;
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
}

.serviceSubHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  width: 100%;
  background-color: #fafafa;
}

.subHeaderAddCategoryBtn:hover {
  cursor: pointer;
}

.subHeaderAddCategoryBtn {
  width: 150px;
  height: 40px;
  border-radius: 49px;
  background-color: #49a4d4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subHeaderAddCategoryBtnText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.dateRangePickerWrapper {
  z-index: 2000;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
}

.dateRangePickerText {
  width: 50px;
  height: 30px;
  border-radius: 4px;
  background-color: #49a4d4;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  line-height: 25px;
  font-family: Montserrat;
  font-weight: 500;
  margin-bottom: 8px;
  margin-left: 85%;
}

.dateRangePickerText:hover {
  cursor: pointer;
}