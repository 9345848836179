.close-cash-salon-summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 10px;
}

.close-cash-salon-name {
  color: #000000;
  font-size: 16px;
  margin-top: 25px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 600;
}

.close-cash-salon-address {
  color: #000000;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  font-family: Montserrat;
}

.close-cash-summary-line {
  height: 1px;
  width: 100%;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.25);
}

.close-cash-date-wrapper div {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.close-cash-date-wrapper div div {
  color: #000000;
  font-size: 12px;
  font-family: Montserrat;
}

.close-cash-date-wrapper {
  padding-left: 14px;
  padding-right: 14px;
}

.close-cash-service-wrapper {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.close-cash-service-wrapper div {
  color: #000000;
  font-size: 14px;
  font-family: Montserrat;
}

.close-cash-sales-summary-text {
  margin-top: 40px;
  color: #000000;
  font-size: 17px;
  font-family: 'Montserrat';
  font-weight: 600;
}
