.editServiceTopHeaderContainer {
  width: 100%;
  background-color: #fafafa;
  height: 51px;
  padding-left: 20px;
  padding-right: 20px;
}

.editServiceTopHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 51px;
}

.editServiceTopHeader:hover {
  cursor: pointer;
}

.addServiceBtnContainer {
  font-family: Montserrat;
  color: #353c42;
  font-size: 14px;
  margin-left: 15px;
  width: 200px;
}

.saveServiceBtnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}

.editServiceBtnContainer:hover {
  cursor: pointer;
}

.editServiceBtnContainer {
  width: 150px;
  height: 40px;
  border-radius: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveServiceBtnText {
  font-family: Montserrat;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}
