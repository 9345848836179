.serviceTextInput {
  height: 45px;
  width: 544px;
  padding-right: 20px;
  border: 1px solid #ebeaea;
  border-radius: 6px;
  color: #434343;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  font-weight: 400;
  font-style: normal;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
  /* margin-bottom: 10px; */
  padding-left: 15px;
}

.serviceTextAreaInput {
  height: 111px;
  width: 544px;
  padding-right: 20px;
  border: 1px solid #ebeaea;
  border-radius: 6px;
  color: #434343;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  font-weight: 400;
  font-style: normal;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-top: 10px;
  outline: none;
}

/*.ant-picker {*/

/*  height: 45px !important;*/

/*  width: 544px !important;*/

/*  border: 1px solid #ebeaea !important;*/

/*  border-radius: 6px !important;*/

/*  background-color: #ffffff !important;*/

/*  box-shadow: 0px 0px 10px 5px #c4d3f020 !important;*/

/*  margin-bottom: 10px !important;*/

/*}*/

/*.ant-picker-input > input {*/

/*  color: #434343 !important;*/

/*  font-size: 14px !important;*/

/*  font-family: Montserrat-Medium !important;*/

/*}*/

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(32, 37, 58, 0.25) !important;
}

.addProductRowWrapper {
  margin: 0;
  display: flex;
  margin-top: 10px;
  align-items: 'center';
}

.addServiceViewContainer {
  width: 100%;
  height: calc(100vh - 61px);
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.16);

}

.addServiceViewWrapper {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
}

.serviceAddFormContainer {
  margin-top: 40px;
  padding-left: 40px;
}

.addServiceDetailsText {
  color: #434343;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
}

.addServiceInputContainer {
  padding-top: 20px;
  padding-left: 40px;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 210px);
}

.addCategoryInputContainer {
  padding-top: 50px;
  padding-left: 50px;
}

.serviceTitleText {
  color: #434343;
  font-size: 16px;
  line-height: 44px;
  font-family: Montserrat;
  font-weight: 500;
}

.addServiceFavoriteText {
  margintop: 5px;
  color: #434343;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
}

.addCategoryBtnContainer:hover {
  cursor: pointer;
}

.addCategoryBtnContainer {
  width: 150px;
  height: 40px;
  background-color: #49a4d4;
  border-radius: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addCategoryBtnText {
  font-family: Montserrat;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}

.serviceTitleCol {
  padding: 0;
  /* padding-right: 0 !important; */
}

.productAllContainer {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 210px);
}

.serviceTitleColInput {
  padding-left: 0 !important;
}

.serviceTitleColInput .toggles {
  /* margin-top: 60px; */
}

.addServiceImageBox {
  width: 100px;
  height: 100px;
  background-color: #efefef;
  border-radius: 10px;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addServiceImageBox:hover {
  cursor: pointer;
}

.addProductImageBox {
  width: 100px;
  height: 100px;
  background-color: #efefef;
  border-radius: 10px;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryPlaceholderText {
  color: rgba(32, 37, 58, 0.25);
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.addCategoryMainContainer {
  background-color: #eff3f6;
  height: 100vh;
  overflow-x: hidden;
}

.createSupplierPhoneInput {
  height: 45px;
  width: 544px;
  padding-right: 20px;
  border: 1px solid #ebeaea;
  border-radius: 6px;
  color: #434343;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  font-weight: 400;
  font-style: normal;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 5px #c4d3f020;
  margin-bottom: 10px;
  padding-left: 15px;
}

.productImageContainer {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.imageRemoveIconContainer {
  position: absolute;
  top: -10px;
  margin-left: 90px;
}

.imageRemoveIconContainer:hover {
  cursor: pointer;
}

.imageRemoveIcon {
  width: 25px;
  height: 25px;
}

.serviceDeleteBtnContainer:hover {
  cursor: pointer;
}

.css-1q0x60z {
  margin: 0 !important;
}

.serviceDeleteBtnContainer {
  width: 140px;
  height: 40px;
  border-radius: 49px;
  border: 1px solid #ff5f5f;
  background-color: #ffd6d6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryDeleteBtnContainer {
  width: 140px;
  height: 40px;
  border-radius: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryDeleteBtnContainer:hover {
  cursor: pointer;
}

.serviceDeleteBtnText {
  font-size: 14px;
  color: #ff5f5f;
  font-family: Montserrat;
  font-weight: 500;
}

.categoryTableContainer {
  height: calc(100vh - 155px);
}

.tableHeaderContainer {
  width: 100%;
  height: 51px;
  background-color: #fafafa;
  padding-left: 10px;
}

.categoryTableHeaderTextContainer {
  display: flex;
  align-items: center;
  height: 51px;
}

.categoryEditIcon:hover {
  cursor: pointer;
}

.categoryTableHeaderText {
  font-size: 16px;
  color: #8a9ca5;
  font-family: Montserrat;
  font-weight: 500;
}

.categoryTableBodyContainer {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 216px);
}

.categoryTableBody {
  width: 100%;
  height: 51px;
  background-color: #ffffff;
  padding-left: 10px;
  border-bottom: 1px solid rgba(32, 37, 58, 0.06);
}

.categoryTableBodyText {
  font-family: Montserrat;
  color: #353c42;
  font-size: 16px;
}

.priceOptionWrapper {
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  align-items: flex-start;
}

.optionButtonWrapper {
  height: 40px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  border-width: 0;
  color: #ffffff;
  font-weight: 400;
  align-items: center;
  border-radius: 49px;
  padding-inline: 24px;
  font-family: Montserrat;
  justify-content: center;
  background-color: #49A4D4;
}

.priceOptionTitle {
  font-size: 16px;
  font-weight: 400;
  color: #8A9CA5;
  margin-Top: 12px;
  max-width: 544px;
  font-family: Montserrat;
}

.priceOptionTitleBold {
  font-size: 16px;
  font-weight: 700;
  color: #8A9CA5;
  margin-bottom: 0;
  font-family: Montserrat;
}



/* ProductOptionView */
.addProductOptionWrapper {
  height: 100vh;
  overflow-x: hidden;
  background-color: #eff3f6;
}




/* ProductModifierView */
.productModifierWrapper {
  display: flex;
  margin-top: 32px;
  flex-direction: row;
  align-items: flex-start;
}
.productModifierItemWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  align-items: flex-start;
}
