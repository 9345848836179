.stockPriceCardContainer {
    width: 100%;
    height: 105px;
    border: 1px solid rgba(112,112,112,0.1);
    border-radius: 3px;
}

.stockTotalText {
    font-size: 18px;
    color: #8A9CA5;
    font-weight: 600;
    font-family: Montserrat;
    padding: 12px 11px;
}

.stockTotalPriceValue {
    font-size: 20px;
    color: rgba(0,0,0,.85);
    font-weight: 700;
    font-family: Montserrat;
    padding: 0 11px;
}
