.productWrapper {
  background-color: #eff3f6 !important;
}

.productBottomWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.progressHeader {
  text-align: center;
  /* background-color: aquamarine; */
  margin-top: 40%;
}

.productSideNavWrapper {
  height: 100%;
  background-color: #f1f1f1;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
}

.productMiddleContainer {
  background-color: rgb(255, 255, 255);
  margin-top: 15px;
  padding-left: 0px;
  height: 89vh !important;
}

.productRightContainer {
  background-color: rgb(255, 255, 255);
  margin-top: 15px;
  margin-left: 10px;
  /*width: 100px !important;*/
}

.background {
  background-color: #f8f8f8;
  width: 100vw;
  height: 100vh;
}

.loginViewBackBtnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 30px;
  padding-left: 30px;
}

.backToWelcomeText {
  font-size: 1.25em;
  color: #434343;
  font-family: Montserrat;
  margin-left: 10px;
}

.loginViewBackBtnContainer:hover {
  cursor: pointer;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
}

.productView {
  /* margin: -8px; */
  /*height: 100vh;*/
  /*overflow: scroll;*/
  background-color: #eff3f6 !important;
}

.mainBodyContainer {
  padding-right: 15px !important;
  padding-left: 10px !important;
}

.backToTextContainer {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
  align-items: center;
  margin-left: 20px;
}

.backToTextContainer:hover {
  cursor: pointer;
}

body {
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}

.welcomeText {
  color: #313131;
  font-size: 48px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-weight: 500;
  margin: 0px;
  font-style: normal;
}

.headerAligh {
  text-align: center;
  /* background-color: aquamarine; */
  margin-top: 134px;
}

.progressHeader {
  text-align: center;
  /* background-color: aquamarine; */
  margin-top: 40%;
}

.forUserName {
  margin-top: 57px;
  margin-left: 130px;
}

.forPasscode {
  margin-top: 22px;
  margin-left: 130px;
}

.validationText {
  color: #ff5f5f;
  font-size: 14px;
  font-family: Montserrat;
}

.labelName {
  color: #394145;
  font-size: 22px;
  font-family: Montserrat;
  }

.inputName {
  width: 100%;
  height: 75px;
  color: #394145;
  border: 1px solid #ebeaea;
  font-size: 18px;
  font-family: 'Montserrat';
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
}

/*input[type='text'],*/

/*select {*/

/*  width: 100%;*/

/*  height: 75px;*/

/*  padding: 12px 20px;*/

/*  margin: 8px 0;*/

/*  display: inline-block;*/

/*  border: 1px solid #ebeaea;*/

/*  background-color: #ffffff;*/

/*  border-radius: 10px;*/

/*  box-sizing: border-box;*/

/*  outline: none;*/

/*}*/

.submitButton {
  height: 75px;
  color: white;
  margin: 8px 0;
  border: none;
  outline: none;
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: #49a4d4;
}

.submitButton:hover {
  background-color: #308ec0;
}

.submitButtonText {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  padding: 0px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
}

.filtersContainer {
  padding-top: 29px;
  background-color: #eff3f6;
  height: 800px;
}

.tableContainer {
  padding-top: 27px;
  margin-left: 100px;
}

.addAppointmentButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
}

.addAppointmentButtonWrapper {
  background-color: #49a4d4;
  width: 183px;
  height: 34px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 4px;
  font-family: Montserrat;
  font-weight: 500;
  font-weight: normal;
  font-style: normal;
  padding: 0px;
  padding: 7px 20px;
  /* margin: 8px 0; */
  display: inline-block;
  box-sizing: border-box;
  outline: none;
}

.addAppointmentButtonText {
  background-color: #49a4d4;
  width: 183px;
  height: 34px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 4px;
  font-family: Montserrat;
  font-weight: 500;
  font-weight: normal;
  font-style: normal;
  padding: 0px;
  padding: 7px 20px;
  /* margin: 8px 0; */
  display: inline-block;
  box-sizing: border-box;
  outline: none;
}

.sideBarContainer {
  margin-top: 15px !important;
  padding-left: 10px !important;
  /*padding-left: 0px !important;*/
  padding-right: 0px !important;
  /* margin-left: 10px; */
  /* background-color: #eaeaea; */
  /* height: 100%; */
}

.menuBackground {
  background-color: black;
  height: 100vh;
  width: 100%;
  margin: -8px;
}

.menuTitles {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Montserrat';
  font-weight: bold;
}

.menuTitles:hover {
  color: #49a4d4;
  cursor: pointer;
}

.signOutText {
  color: #ffffff;
  font-size: 22px;
  font-family: 'Montserrat';
  margin-left: 20px;
}

.signOutText:hover {
  color: #49a4d4;
  cursor: pointer;
}

.menuLogoutContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.menuLogoutButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.cancelIcon:hover {
  cursor: pointer;
}

.menuSectionsContainer {
  display: flex;
  padding-top: 75px;
  flex-direction: row;
  justify-content: flex-start;
}

.subHeaderContainer {
  padding-top: 1px;
  margin-left: 100px;
  background-color: #ffffff;
}

.incrementTextInput {
  width: 40px;
  height: 25px;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.incrementTextInput:focus {
  outline: none;
}

.bookingDetailsTopBar {
  height: 51px;
  width: 100%;
  background-color: #fafafa;
  border-bottom-color: #fafafa;
}

.bookingDetailsTopRow {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 5px !important;
}

.backIcon {
  padding-right: 0;
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appointmentTextContainer {
  display: flex !important;
  align-items: center !important;
  height: 51px !important;
  justify-content: flex-start !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.appointmentText {
  color: #88938f;
  font-size: 14px;
  font-family: Montserrat;
}

.clearBoxContainer {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  padding-right: 30px !important;
}

.clearIconContainer {
  width: 60px;
  height: 34px;
  background-color: rgba(255, 0, 0, 0.25);
  border-radius: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.clearText {
  font-family: Montserrat;
  color: #ff0000;
  font-size: 14px;
}

.bookingDetailsBottomContainer {
  height: 66vh;
  padding-top: 20px;
  padding-left: 30px;
  background-color: #ffffff;
  overflow: scroll;
}

.bookingItemDetailsContainer {
  display: flex !important;
  align-items: center !important;
}

.bookingItemsName {
  color: #434343;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
}

.bookingItemPrice {
  color: #434343;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
}

.bookingItemCountIncrementContainer {
  display: flex !important;
  align-items: center !important;
}

.bookingCountContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bookingItemBottomLine {
  border-bottom: 1px solid rgba(32, 37, 58, 0.06);
  margin-right: 30px;
  margin-top: 10px;
}

.bookingDetailsBottomBoxContainer {
  width: 100%;
  height: 69px;
  background-color: #f9f9f9;
}

.bookingChargesBoxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 40px;
  padding-top: 12px;
}

.subTotalText {
  font-family: Montserrat;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.bookingChargesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 40px;
  margin-top: 5px;
}

.totalPriceBoxContainer {
  width: 100%;
  height: 69px;
  background-color: #ededed;
}

.totalPriceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 40px;
  height: 69px;
}

.totalPriceText {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
}

.totalPriceValueText {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
}

.paymentDetailsContainer {
  background-color: #ffffff;
  width: 100%;
  height: 89vh;
  margin-top: 10px;
  margin-bottom: 20px;
}

.totalPriceTextValue {
  font-size: 51px;
  font-family: Montserrat;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  text-align: center;
  padding-top: 70px;
}

.cashCardContainer {
  width: 600px;
  height: 69px;
  border: 1px solid #ede2e2;
  margin-left: 18px;
  margin-top: 20px;
}

.cashCardContainer:hover {
  cursor: pointer;
}

.cashCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  height: 69px;
}

.cashImageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cashCardBox {
  display: flex;
  justify-content: center;
}

.cashText {
  font-size: 16px;
  color: #434343;
  font-family: Montserrat;
  font-weight: 500;
}

.cardComponentContainer {
  margin-left: 53px;
  margin-top: 30px;
  height: 200px;
}

.cardNumberContainer {
  margin-right: 0;
  padding-right: 0;
}

.cardNumberText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  color: #434343;
}

.cardInput {
  width: 180px;
  height: 48px;
  border: 1px solid #ede2e2;
  padding-left: 30px;
  font-family: Montserrat;
  font-size: 16px;
  color: #434343;
}

.cardDropDownContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
}

.selectedCardText {
  font-family: Montserrat;
  font-weight: 500;
  color: #434343;
  font-size: 16px;
}

.selectedCardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  justify-content: space-between;
}

.cardDropDownInput {
  width: 180px;
  height: 48px;
  border: 1px solid #ede2e2;
  font-family: Montserrat;
  font-size: 16px;
  color: #434343;
}

.cardInput:focus {
  outline: none;
}

.cardComponentPriceContainer {
  margin-top: 10px;
  /*display: flex;*/
  /*align-items: center;*/
}

.cardComponentNameText {
  margin-left: 18px;
  margin-top: 20px;
}

.cardComponentNameInputContainer {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.cardComponentNameInput {
  font-family: Montserrat;
  font-size: 16px;
  color: #434343;
  width: 418px;
  height: 48px;
  border: 1px solid #ede2e2;
  padding-left: 30px;
}

.cardComponentNameInput:focus {
  outline: none;
}

.cardComponentBtnContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 95%;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.cancelBtnComponent {
  width: 280px;
  height: 59px;
  background-color: #f5f5f5;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancelBtnComponent:hover {
  cursor: pointer;
}

.cancelText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  color: #cccccc;
}

.completeOrderBtnContainer {
  width: 280px;
  height: 59px;
  background-color: #37ceaf;
  border-radius: 4px;
  margin-left: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.completeOrderBtnContainer:hover {
  cursor: pointer;
}

.completeOrderText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.cashComponentBtnContainer {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
}

.cashComponentTotalContainer {
  margin-left: 53px;
  margin-top: 30px;
}

.cashComponentTotalText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  color: #434343;
}

.cashComponentTotalValueContainer {
  width: 471px;
  height: 48px;
  border: 1px solid #ede2e2;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.cashComponentTotalValue {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  color: #434343;
}

.cashComponentCashContainer {
  margin-left: 53px;
  margin-top: 20px;
}

.cashComponentCashText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  color: #434343;
}

.cashComponentCashValueContainer {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.cashComponentCashValue {
  font-family: Montserrat;
  font-size: 16px;
  color: #434343;
  width: 418px;
  padding-left: 50px;
  height: 48px;
  border: 1px solid #ede2e2;
}

.cashComponentCashInput {
  font-family: Montserrat;
  font-size: 16px;
  color: #434343;
  width: 448px;
  padding-left: 50px;
  height: 48px;
  border: 1px solid #ede2e2;
}

.cashComponentCashValue:focus {
  outline: none;
}

.expectedChangeContainer {
  width: 247px;
  height: 34px;
  border-radius: 60px;
  background-color: rgba(62, 122, 161, 0.25);
  margin-left: 53px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.expectedChangeText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: #3e7aa1;
}

.expectedValue {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 14px;
  color: #3e7aa1;
  font-weight: 700;
  margin-left: 5px;
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #4962b3;
  font-size: 12px;
  border: 1px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.Styles__sideBarContainer__3KOyv {
  padding-right: 0px !important;
}

.line {
  height: 100%;
  width: 1px;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.25);
}

/*CloseCash=======>*/

.closeCashWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
}

.closeCashHeaderWrapper {
  height: 51px;
  background-color: #fafafa;
  align-items: center;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.closeCashHeaderText {
  color: #88938f;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
}

.closeCashHeaderIconWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 150px;
}

.closeCashHeaderIcon {
  padding: 10px 10px;
}

.closeCashHeaderIcon:hover {
  cursor: pointer;
}

.closeCashServiceWrapper {
  height: 41px;
  background-color: #fafafa;
  align-items: center;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 42px;
}

/*Profile=======>*/

.profileWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
}

/*Transactions*/

.transactionsHeaderWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.transactionsHeaderWrapperInfo {
  width: 100%;
  margin-top: 38px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
}

.transactionsRightWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
  margin-right: 10px;
}

.transactionsRightWrapperInfo {
  width: 100%;
  margin-top: 32px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
}

/*Settings=======>*/

.settingsWrapper {
  width: 100%;
  display: flex;
  padding: 10px;
  border-radius: 3px;
  flex-direction: row;
  height: calc(100vh - 61px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
}

.serviceCustomizedContainer {
  background-color: #eff3f6;
  height: 100vh;
  overflow-x: hidden;
}

.serviceCustomizedWrapper {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
}

.settingsWifiWrapper {
  padding-left: 30px;
  padding-right: 45px;
  padding-top: 15px;
}

.allServiceWrapper {
  padding-left: 20px;
  padding-right: 45px;
  padding-top: 15px;
}

.categoryTableWrapper {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.menuWrapper {
  padding-left: 8px;
  padding-right: 8px;
}

/*EditMenu=======>*/

.editMenuWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
}

.editMenuFavoriteWrapper {
  padding-left: 30px;
  padding-right: 45px;
  padding-top: 15px;
}

.preferenceText {
  margin: 0px;
  right: 40px;
  bottom: 24px;
  font-size: 16px;
  text-align: end;
  cursor: pointer;
  color: #49a4d4;
  font-weight: 500;
  font-style: normal;
  position: absolute;
  font-family: 'Montserrat';
  text-decoration: underline;
}

/*.please-wait-text-container {*/

/*  margin-top: 20px;*/

/*  color: #868686;*/

/*  font-size: 16px;*/

/*  font-family: Montserrat;*/

/*}*/

/*.please-wait-text {*/

/*  text-align: center;*/

/*  color: #868686;*/

/*  font-size: 16px;*/

/*  font-family: Montserrat;*/

/*}*/

/*.onboarded-text {*/

/*  text-align: center;*/

/*  margin-top: 4px;*/

/*  color: #868686;*/

/*  font-size: 16px;*/

/*  font-family: Montserrat;*/

/*}*/