/* //wifi model */
@import '~react-calendar/dist/Calendar.css';
@import '~react-date-picker/dist/DatePicker.css';

.model {
  background-color: rgb(255, 255, 255);
  width: 620px;
  /*height: 428px;*/
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
  box-shadow: 0 3px 10px rgba(197, 197, 242, 0.4) !important;
}

.authenticationModel {
  background-color: #707070;
  width: 300px;
  height: 180px;
  outline: none;
  padding-right: 20px;
  padding-left: 20px;
}

.modelTitle {
  color: #ffffff;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modelStyle {
  background-color: #353c42;
  height: 61px;
  margin: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modelSubTitle {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 200;
  font-style: normal;
  padding-left: 20px;
  margin-top: 27px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: 'rgba(175, 175, 175, 0.5)';
}

.wifiPasswordText {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  padding-left: 10px;
  /* margin-top: 27px; */
}

.wifiPasswordRow {
  align-items: center;
  justify-content: center;
}

.wifiSettingsCheckboxRow {
  align-items: center;
  justify-content: flex-end;
}

/*input[type='password'],*/

/*select {*/

/*  width: 100%;*/

/*  height: 75px;*/

/*  padding: 12px 20px;*/

/*  margin: 8px 0;*/

/*  display: inline-block;*/

/*  border: 1px solid #ebeaea;*/

/*  background-color: #ffffff;*/

/*  border-radius: 10px;*/

/*  box-sizing: border-box;*/

/*  outline: none;*/

/*}*/

.checkBox {
  height: 15px;
  width: 15px;
  border: 1px solid #ebeaea;
  border-radius: 4px;
}

.checkBoxRow {
  display: flex;
  /* or inline-flex */
  flex-direction: row;
  justify-content: flex-start;
}

.checkBoxTitleText {
  color: #8a9ca5;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
  margin-top: 0px;
  margin-left: 14px;
}

/* //model */

.model {
  background-color: rgb(255, 255, 255);
  width: 331px;
  height: 639px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
}

.reportModelTitle {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  /* padding-left: 20px; */
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.salonModelAddress {
  color: #262626;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 200;
  font-style: normal;
  /* padding-left: 20px; */
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 4px;
}

.dashBorder {
  border-top: 1px dashed #000000;
  margin-left: 10px;
  margin-right: 10px;
}

.modeldateRow {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}

.ModelDateValue {
  color: #353c42;
  font-size: 10px;
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
  margin-top: 0px;
  margin-left: 14px;
  text-align: right;
}

.Modeldate {
  color: #353c42;
  font-size: 10px;
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
  margin-top: 0px;
  /* margin-left: 14px; */
  text-align: left;
}

.salesSummary {
  margin-left: 14px;
  color: #353c42;
  font-size: 17px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  padding-top: 10px;
}

.modelSalesText {
  /* margin-left: 14px; */
  color: #353c42;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  margin-bottom: 2px;
  margin-top: 2px;
}

.modelSalesValue {
  /* margin-left: 14px; */
  color: #353c42;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  text-align: right;
  margin-bottom: 2px;
  margin-top: 2px;
}

/* new  */

/* //create customer model */

.model {
  background-color: rgb(255, 255, 255);
  width: 620px;
  height: 649px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
}

.modelStyle {
  background-color: #353c42;
  height: 61px;
  margin: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modelSubTitle {
  color: #000000;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 200;
  font-style: normal;
  padding-left: 20px;
  margin-top: 27px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: 'rgba(175, 175, 175, 0.5)';
}

.cusInfo {
  color: #8a9ca5;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  font-weight: normal;
  font-style: normal;
  padding-left: 10px;
  margin-bottom: 0px;
}

.wifiSettingsCheckboxRow {
  align-items: center;
  justify-content: flex-end;
}

/*input[type='text'],*/

/*select {*/

/*  width: 575px;*/

/*  height: 75px;*/

/*  padding: 12px 20px;*/

/*  !* margin: 8px 0; *!*/

/*  display: inline-block;*/

/*  border: 1px solid #ebeaea;*/

/*  background-color: #ffffff;*/

/*  border-radius: 10px;*/

/*  box-sizing: border-box;*/

/*  outline: none;*/

/*}*/

.checkBox {
  height: 15px;
  width: 15px;
  border: 1px solid #ebeaea;
  border-radius: 4px;
}

.checkBoxRow {
  display: flex;
  /* or inline-flex */
  flex-direction: row;
  justify-content: flex-start;
}

.checkBoxTitleText {
  color: #8a9ca5;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
  margin-top: 0px;
  margin-left: 14px;
}

/* //model */

/* //wifi model */

.Calmodel {
  background-color: rgb(255, 255, 255);
  width: 570px;
  height: 578px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
}

.productSelectModel {
  background-color: rgb(255, 255, 255);
  width: 300px;
  height: 160px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
}

.LogoutModalWrapper {
  width: 459px;
  height: 236px;
  outline: none;
  border-radius: 5px;
  background-color: #ffffff;
}

/*ProductDeleteModal==========>*/

.productDeleteModalWrapper {
  width: 459px;
  height: 236px;
  outline: none;
  border-radius: 5px;
  background-color: #ffffff;
}

.productDeleteModalWrapperInfo {
  width: 100%;
  height: 100%;
  padding: 28px 60px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.orderDeleteModalWrapperInfo {
  width: 100%;
  height: 100%;
  padding: 28px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.productDeleteModalCrossWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.productDeleteModalCross:hover {
  cursor: pointer;
}

.productDeleteModalHeaderText {
  font-size: 21px;
  color: #000000;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: bold;
}

.productDeleteModalTitleText {
  font-size: 14px;
  color: #000000;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 500;
  margin-top: 24px;
}

.productDeleteModalBtnWrapper {
  width: 100%;
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  justify-content: space-between;
}

.orderDeleteModalBtnWrapper {
  width: 100%;
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  justify-content: space-between;
}

.productDeleteModalBtnRemove {
  width: 126px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff8383;
  border-radius: 5px;
  border: none;
}

.productDeleteModalBtnRemove:hover {
  cursor: pointer;
}

.productDeleteModalBtnText {
  color: #ffffff;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
}

.productDeleteModalBtnCancel {
  width: 126px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #49a4d4;
  border-radius: 5px;
  border: none;
}

.productDeleteModalBtnCancel:hover {
  cursor: pointer;
}

.modelTitle {
  color: #ffffff;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calculatorInputFiledRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modelStyle {
  background-color: #353c42;
  height: 61px;
  margin: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modelSubTitle {
  color: rgba(0, 0, 0, 0.85);
  font-size: 21px;
  font-family: 'Montserrat';
  font-weight: BOLD;
  font-style: normal;
  padding-left: 20px;
  margin-top: 27px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(175, 175, 175, 0.5);
}

.calculatorNumbers {
  color: #969696;
  font-size: 31px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
}

.editProductPriceModal {
  width: 528px;
  height: 437px;
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(255, 255, 255);
}


/* SelectOptionModal */
.selectOptionModal {
  width: 544px;
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/*CreateCustomerModel=============>*/
.createCustomerModal {
  width: 528px;
  /*height: 610px;*/
  outline: none;
  background-color: rgb(255, 255, 255);
  padding-bottom: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.createCustomerHeaderWrapper {}

.createCustomerHeaderText {
  color: #ffffff;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.customerModelCloseIcon:hover {
  cursor: pointer;
}

.createCustomerInformation {
  color: #49a4d4;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  padding-left: 20px;
  display: flex;
  padding-top: 25px;
}

.createCustomerBottomWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 46px;
  padding-right: 46px;
}

.createCustomerInputWrapper {
  width: 100%;
  margin-top: 20px;
}

.createCustomerInputLabel {
  color: #8a9ca5;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  margin-bottom: 5px;
}

.addClientPhoneInput {
  display: flex;
  align-items: center;
  width: 385px;
  height: 47px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(197, 197, 242, 0.4);
  padding-left: 15px;
}

.createCustomerInput {
  height: 45px;
  width: 100%;
  color: #000000;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  margin-bottom: 5px;
  border: 1px solid #8a9ca5;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.createInputContainer {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 45px;
  margin-top: 40px;
}

.addCustomerBtnContainer {
  width: 159px;
  height: 40px;
  background-color: #49a4d4;
  border-radius: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addCustomerBtn {
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  color: #ffffff;
}

.addCustomerBtn:hover {
  cursor: pointer;
}

.cancelButtonContainer {
  width: 122px;
  height: 40px;
  border: 1px solid #8a9ca5;
  border-radius: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.cancelBtn {
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  color: #8a9ca5;
}

.cancelBtn:hover {
  cursor: pointer;
}

.productSelectModalContainer {
  width: 300px;
  height: 160px;
  border-radius: 5px;
  background-color: #707070;
}

.selectProductText {
  font-size: 20px;
  color: #ffffff;
  font-family: Montserrat;
  font-weight: 500;
  text-align: center;
  padding-top: 25px;
}

.youShouldText {
  font-size: 14px;
  color: #ffffff;
  font-family: Montserrat;
  text-align: center;
  padding-top: 5px;
}

.openTheKeyboardText {
  font-size: 14px;
  color: #ffffff;
  font-family: Montserrat;
  text-align: center;
}

.selectProductBottomBtnContainer {
  display: flex;
  flex-direction: row;
  margin-top: 26px;
}

.productCancelBtnContainer {
  width: 200px;
  height: 50px;
  border-top: 1px solid #545456;
  border-right: 1px solid #545456;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productCancelBtnContainer:hover {
  cursor: pointer;
}

.productOkBtnContainer:hover {
  cursor: pointer;
}

.productOkBtnContainer {
  width: 400px;
  height: 50px;
  border-top: 1px solid rgba(84, 84, 88, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.productRemoveOkBtnContainer {
  width: 400px;
  height: 50px;
  border-top: 1px solid rgba(84, 84, 88, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(84, 84, 88, 0.25);
}

.productRemoveOkBtnContainer:hover {
  cursor: pointer;
}

.selectProductCancelBtnText {
  color: #0a84ff;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: bold;
}

.productRemoveBtnText {
  color: #ff4141;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: bold;
}

.calculationModalContainer {
  width: 528px;
  height: 424px;
  display: flex;
  flex-direction: row;
}

.calculationBoxContainer {
  width: 132px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #f3f3f3;
}

.calculationBox {
  width: 132px;
  height: 106px;
  align-items: center;
  justify-content: center;
  border: 1px solid #f3f3f3;
  display: flex;
  font-size: 31px;
  font-family: Montserrat;
  color: rgba(0, 0, 0, 0.85);
}

.calculationBox:hover {
  cursor: pointer;
}

.calculationCText:hover {
  cursor: pointer;
}

.calculationCText {
  width: 132px;
  height: 212px;
  align-items: center;
  justify-content: center;
  border: 1px solid #f3f3f3;
  display: flex;
  font-size: 31px;
  font-family: Montserrat;
  color: rgba(0, 0, 0, 0.85);
}

.middleCalculationBox {
  width: 132px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #f3f3f3;
}

/*AddAppointmentModal=====>*/

.addAppointmentModel {
  width: 528px;
  /*height: 610px;*/
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
  background-color: rgb(255, 255, 255);
  padding-bottom: 20px;
}

.addAppointmentHeaderWrapper {
  height: 61px;
  display: flex;
  flex-direction: row;
  background-color: #353c42;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding-right: 14px;
  padding-left: 20px;
}

.saveOrderHeaderWrapper {
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: rgba(138, 156, 165, .23);
}

.saveOrderHeaderWrapperContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.addAppointmentHeaderText {
  color: #ffffff;
  font-size: 14px;
  font-weight: 200;
  font-style: normal;
  font-family: 'Montserrat';
}

.addAppointmentWrapper {
  margin-right: 0;
  padding-right: 0;
}

.addAppointmentButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.addAppointmentHeaderTitleText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  color: #49a4d4;
  margin-top: 25px;
  margin-bottom: 8px;
}

.addAppointmentColWrapper {
  margin-top: 20px;
}

.addAppointmentLabelText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  color: #8a9ca5;
  margin-bottom: 5px;
}

.addAppointmentInput {
  width: 100%;
  height: 42px;
  border: 1px solid #8a9ca5;
  font-family: Montserrat;
  font-size: 16px;
  color: #434343;
  border-radius: 4px;
}

.addAppointmentPhoneInput {
  width: 100%;
  display: flex;
  align-items: center;
  height: 42px;
  border-radius: 4px;
  margin-bottom: 8px;
  border: 1px solid #8a9ca5;
  background-color: #ffffff;
  /*box-shadow: 0 3px 10px rgb(197 197 242 / 0.4);*/
}

.addAppointmentSaveButton {
  width: 160px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #49a4d4;
  border: none;
  border-radius: 49px;
  outline: none;
  cursor: pointer;
  margin-right: 14px;
}

.addAppointmentCancelButton {
  width: 122px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 49px;
  outline: none;
  border: 1px solid #8a9ca5;
  cursor: pointer;
}

.addAppointmentSaveButtonText {
  color: #ffffff;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.addAppointmentCancelButtonText {
  color: #8a9ca5;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.addAppointmentSelectedInput {
  width: 100%;
  height: 38px;
  justify-content: center;
  /*padding-bottom: 5px;*/
}

.addAppointmentPriceInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  border: 1px solid #8a9ca5;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
}

.addAppointmentPriceText {
  color: #00000085;
  font-size: 16px;
  font-family: Montserrat;
}

/*input[type='text'], select {*/

/*  height: 32px;*/

/*  padding: 0;*/

/*  margin: 0;*/

/*  border-radius: 4px;*/

/*}*/

.special-label {
  font-family: Montserrat !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #8a9ca5 !important;
  margin-bottom: 5px !important;
}

.searchWrapper {
  min-height: 42px;
  border-radius: 4px;
}

.addAppointmentMultiInput {
  width: 100%;
  height: 200px;
  border: 1px solid #8a9ca5;
  font-family: Montserrat;
  font-size: 16px;
  color: #434343;
  border-radius: 4px;
}

.addAppointmentPickerInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 42px;
  border: 1px solid #8a9ca580;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
  /*margin-top: 10px;*/
}

.addAppointmentPickerInputInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
}

.react-datetime-picker__wrapper {
  border: none;
}

.style {
  width: 100px;
}

.displayBlock {
  width: 100%;
  border: 1px solid #8a9ca5;
}

.multiSelectContainer ul {
  width: 100%;
}

.quickCustomerModel {
  width: 528px;
  height: 418px;
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(255, 255, 255);
}

.addDiscountModal {
  width: 528px;
  height: 353px;
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(255, 255, 255);
}

.selectEmployeeModal {
  width: 528px;
  max-height: 344px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  outline: none;
  background-color: rgb(255, 255, 255);
  padding-bottom: 16px;
}

.selectCustomerModal {
  width: 648px;
  height: 80vh;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  outline: none;
  background-color: rgb(255, 255, 255);
  padding-bottom: 16px;
}

.selectVehicleModal {
  width: 528px;
  max-height: 548px;
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(255, 255, 255);
  padding-bottom: 16px;
}

.selectDateAndTimeModal {
  width: 798px;
  height: 520px;
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(255, 255, 255);
  padding-bottom: 16px;
}

.createQuickItemModel {
  width: 528px;
  max-height: 548px;
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(255, 255, 255);
  padding-bottom: 16px;
}

.createCategoryModal {
  width: 528px;
  max-height: 430px;
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(255, 255, 255);
  padding-bottom: 16px;
}

.createWarrantyModal {
  width: 548px;
  outline: none;
  max-height: 612px;
  padding-bottom: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(255, 255, 255);
}

.multipleOrderModal {
  width: 638px;
  outline: none;
  max-height: 565px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgb(255, 255, 255);
}

/*CashInOutModal=====>*/
.cashInOutModal {
  width: 528px;
  /* height: 565px; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
  background-color: rgb(255, 255, 255);
  padding-bottom: 16px;
}

.cashInOutModalContainer {
  width: 528px;
  height: 440px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
  background-color: rgb(255, 255, 255);
  padding-bottom: 16px;
}

.cashInOutModalHeaderWrapper {
  height: 61px;
  display: flex;
  flex-direction: row;
  background-color: #353c42;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding-right: 14px;
  padding-left: 20px;
}

.cashInOutModalHeaderText {
  color: #ffffff;
  font-size: 14px;
  font-weight: 200;
  font-style: normal;
  font-family: 'Montserrat';
}

.cashInOutModalBottomWrapper {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 55px;
}

.cashInOutModalColWrapper {
  margin-top: 33px;
}

.cashInOutModalLabelText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12px;
  color: #8a9ca5;
  margin-bottom: 5px;
}

.cashInOutModalInputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.cashInOutModalCurrencyText {
  position: absolute;
  bottom: 11px;
  left: 20px;
}

.cashInOutModalInput {
  width: 100%;
  height: 42px;
  border: 1px solid #8a9ca5;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-left: 60px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  color: #434343;
  border-radius: 4px;
}

.cashInOutModalInput:focus {
  outline: none;
}

/*.cashInOutModalInput {*/

/*  width: 100%;*/

/*  height: 42px;*/

/*  border: 1px solid #8A9CA5;*/

/*  font-family: Montserrat;*/

/*  font-size: 16px;*/

/*  color: #434343;*/

/*  border-radius: 4px;*/

/*  padding-left: 15px;*/

/*  display: flex;*/

/*  flex-direction: row;*/

/*  align-items: center;*/

/*}*/

.cashInOutModalFilterButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cashInOutModalFilterButton {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  padding-right: 12px;
  padding-left: 12px;
  border: 1px solid #dedede;
}

.cashInOutModalFilterButton:hover {
  cursor: pointer;
}

.cashInOutModalFilterSelectButton {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #39b97d;
  border-radius: 10px;
  cursor: pointer;
  padding-right: 12px;
  padding-left: 12px;
  border: 1px solid #39b97d;
}

.cashInOutModalFilterSelectButton:hover {
  cursor: pointer;
}

.cashInOutModalFilterSelectButtonText {
  color: #ffffff;
  font-size: 12px;
  font-family: Montserrat;
}

.cashInOutModalFilterSelectButtonText:hover {
  cursor: pointer;
}

.cashInOutModalFilterButtonText {
  color: #000000;
  font-size: 12px;
  font-family: Montserrat;
}

.cashInOutModalFilterButtonText:hover {
  cursor: pointer;
}

.cashInOutModalBottomButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 60px;
}

.cashInOutModalBottomDeleteButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.cashInOutModalOpenButton {
  width: 160px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #49a4d4;
  border: none;
  border-radius: 49px;
  cursor: pointer;
  margin-right: 14px;
}

.cashInOutModalSaveButton {
  width: 122px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #39B97D;
  /* background-color: #b7dfb8; */
  border-radius: 49px;
  cursor: pointer;
}

.cashInOutModalDeleteButton {
  width: 112px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF2626;
  /* background-color: #b7dfb8; */
  border-radius: 49px;
  cursor: pointer;
}

.cashInOutModalSaveButtonDisabled {
  width: 122px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #39B97D; */
  background-color: #b7dfb8;
  border-radius: 49px;
  cursor: pointer;
}

.cashInOutModalOpenText {
  color: #ffffff;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

.cashInOutModalSaveText {
  color: #ffffff;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
}

/*GenerateReportModal=====>*/

.generateReportModal {
  width: 331px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
  background-color: rgb(255, 255, 255);
  padding-bottom: 100px;
}

.generateReportModalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 639px;
}

.generateReportDetailsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 33px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.generateReportDetailsSalonText {
  color: #000000;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
}

.generateReportDetailsAddressText {
  color: #000000;
  font-size: 14px;
  font-family: Montserrat;
  margin-top: 9px;
}

.generateReportDetailsBottomLine {
  height: 1px;
  width: 100%;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.25);
}

.generateReportTimesWrapper {
  padding-left: 14px;
  padding-right: 14px;
}

.generateReportTimesWrapperInfo {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.generateReportDetailsTimeText {
  color: #000000;
  font-size: 12px;
  font-family: Montserrat;
}

.generateReportDetailsSummaryText {
  color: #000000;
  font-size: 17px;
  font-family: 'Montserrat';
  font-weight: 600;
}

.generateReportServiceWrapper {
  margin-top: 90px;
}

.generateReportDetailsServiceText {
  color: #000000;
  font-size: 14px;
  font-family: Montserrat;
  margin-top: 12px;
}

.generateReportDetailsSaleText {
  color: #000000;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: bold;
  margin-top: 12px;
}

.generateReportDetailsTotalText {
  color: #000000;
  font-size: 14px;
  margin-top: 12px;
  font-family: Montserrat;
  font-weight: bold;
  font-weight: bold;
}

.generateReportStylistWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 67px;
  background-color: #f5f5f5;
  margin-top: 63px;
  margin-left: 6px;
  margin-right: 6px;
  border: 1px solid #cccccc;
  cursor: pointer;
}

.generateReportStylistText {
  color: #cccccc;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: bold;
  font-style: normal;
}

.generateReportPrintWrapper {
  height: 67px;
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #49a4d4;
  margin-left: 6px;
  margin-right: 6px;
  border: 1px solid #49a4d4;
  cursor: pointer;
}

.generateReportPrintText {
  color: #ffffff;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-weight: bold;
  font-style: normal;
}

/*WifiPasswordModal=====>*/

.wifiPasswordModal {
  width: 528px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
  background-color: rgb(255, 255, 255);
  padding-bottom: 40px;
}

.wifiPasswordHeaderWrapper {
  height: 61px;
  display: flex;
  flex-direction: row;
  background-color: #353c42;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  align-items: center;
  padding-right: 14px;
  padding-left: 20px;
}

.wifiPasswordHeaderText {
  color: #ffffff;
  font-size: 14px;
  font-weight: 200;
  font-style: normal;
  font-family: 'Montserrat';
}

.wifiPasswordHeaderDescWrapper {
  padding: 27px 20px;
}

.wifiPasswordHeaderDescText {
  color: #00000085;
  font-size: 12px;
  font-family: 'Montserrat';
}

.wifiPasswordInputWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 31px;
}

.wifiPasswordLabelText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.wifiPasswordInput {
  width: 337px;
  height: 45px;
  background-color: #ffffff;
  border: 1px solid #ebeaea;
  padding-left: 10px;
  border-radius: 4px;
}

.wifiPasswordCheckboxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 150px;
  margin-top: 25px;
}

.wifiPasswordCheckboxWrapperInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.wifiPasswordCheckboxLabel {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: #8a9ca5;
}

.wifiPasswordButtonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 80px;
}

.PhoneInput {
  padding-top: 12px !important;
}

.PhoneInputInput {
  border: none !important;
}

/* add printer model */
.addPrinterModal {
  width: 628px;
  height: 420px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
  background-color: rgb(255, 255, 255);
  padding-bottom: 16px;
}

.approvalEmployeeBox {
  /* margin-right: 30px; */
  margin-bottom: 30px;
  width: 427px;
  height: 102px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  flex-direction: row;
}

.approvalEmployeeBox:hover {
  cursor: pointer;
}

.welcomeImageContainer {
  width: 90px;
  height: 90px;
  border-radius: 50px;
}

.welcomeEmployeeName {
  font-family: Montserrat;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.125em;
  text-transform: capitalize;
}

.welcomeEmployeeType {
  font-family: Montserrat;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  font-size: 0.875em;
}

.welcomeEmployeeCardContainer {
  overflow-x: scroll;
  width: 100%;
  display: flex;
  /* height: calc(100vh - 605px); */
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  /* padding-left: 25px; */
  margin-top: 40px;
  margin-bottom: 40px;
}

.approvalEmployeeModal {
  width: 600px;
  /* min-height: 550px; */
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(255, 255, 255);
  padding-bottom: 16px;
}

.approvalUserImageContainer {
  width: 86px;
  height: 86px;
  border-radius: 50px;
}

.enterPinModalContainer {
  margin-left: 53px;
  margin-right: 53px;
  margin-top: 50px;
}

.enterPinText {
  font-size: 22px;
  color: #394145;
  font-family: Montserrat;
  font-weight: 400
}

.pinBottomBtnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 40px 20px 52px;
}

.approvalModalBottomBtn {
  display: flex;
  margin-left: 30px;
  margin-right: 20px;
  align-items: center;
  justify-content: space-between;
}

.errorPinText {
  color: #FF9696;
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 400
}


.preferenceModel {
  width: 768px;
  outline: none;
  padding: 24px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
}

.preferenceWrapper {
  width: '100%';
  display: flex;
  height: '100%';
  align-items: center;
  flex-direction: column;
  background-color: '#F8F8F8';
}

.preferenceClose {
  top: 24px;
  right: 24px;
  display: flex;
  cursor: pointer;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.preferenceHeaderText {
  font-size: 48px;
  color: #000000;
  line-height: 44px;
  text-align: center;
}

.preferenceDescText {
  font-size: 18px;
  margin-bottom: 20px;
  color: #394145;
  text-align: center;
}

.preferenceSetupWrapper {
  margin-top: 35px;
}

.preferenceSetupItemWrapper {
  margin-bottom: 35px;
}

.preferenceSetupRadioWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;
}

.preferenceSetupTitleText {
  font-size: 24px;
  font-weight: 600;
  color: #394145;
  text-align: center;
  text-transform: uppercase;
}
.preferenceSetupDescText {
  font-size: 16px;
  color: #394145;
}
.preferenceRadioWrapper {
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  border: 2px solid #8A9CA5;
}

.preferenceRadioIcon {
  width: 20px;
  height: 20px;
  border-radius: 12px;
  background-color: #49A4D4;
}

.preferenceButtonWrapper {
  width: 375px;
  height: 48px;
  border: none;
  margin-top: 40px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: #49A4D4;
}

.preferenceButtonText {
  font-size: 28px;
  color: #fff;
}

.preferenceOfflineWrapper {
  width: 100%;
  height: 100%;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.05);
}

.preferenceOfflineMainWrapper {
  top: 40%;
  left: 25%;
  padding: 30;
  width: 50%;
  display: flex;
  border-radius: 16px;
  align-items: center;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.8);
}

.preferenceOfflineTitle {
  font-size: 16px;
  color: #000000;
  text-align: center;
  font-weight: bold;
}

.preferenceOfflineDesc {
  font-size: 16px;
  margin-top: 8px;
  color: #000000;
  text-align: center;
}

.preferenceButton {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
}

/* CreateWarrantyModal */
.warrantyModalWrapper {
  width: 548px;
  outline: none;
  max-height: 768px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgb(255, 255, 255);
}